import React from "react";
import styled from "styled-components";
import sinLogo from "../../assets/images/logo.png"
import logoSmall from "../../assets/images/logoSmall.png"

const Development =() =>{
  return (
    <Container>
      <Logo src={sinLogo}/>
      <Contact>
        <TextBox>
          <Text>Contact Us:</Text>
        </TextBox>
        <Email href="mailto:contact@sinsenstudio.com">
          <Text>contact@sinsenstudio.com</Text>
        </Email>
      </Contact>
      <BottomTextBox>
        <BottomText>WEBSITE IN DEVELOPMENT  WEBSITE IN DEVELOPMENT  WEBSITE IN DEVELOPMENT  WEBSITE IN DEVELOPMENT  WEBSITE IN DEVELOPMENT  WEBSITE IN DEVELOPMENT </BottomText>
      </BottomTextBox>
    </Container>
  );
}

export default Development;

const Container = styled.div`
height: 100vh;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`
const Logo = styled.img`
width: 700px;
margin-top: 70px;
margin-bottom: 70px;


@media only screen and (max-width: 950px) {
  width: 300px;
  
}
`
const Contact = styled.div`
padding-top: 40px;

`

const TextBox = styled.div`
display: flex;
justify-content: center;
align-items: center;
`
const Text = styled.p`
padding-top: 10px;
display: flex;
justify-content: center;
align-items: center;
font-size: 35px;

@media only screen and (max-width: 950px) {
  font-size: 30px;
}
`
const Email = styled.a`
display: flex;
justify-content: center;
align-items: center;
cursor: pointer;
`
const BottomTextBox = styled.div`
position: absolute;
text-overflow: clip;
white-space: nowrap;
bottom: 0;
`
const BottomText = styled.h1`
color: #FFB800;
`