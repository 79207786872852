import {Route, Routes} from "react-router-dom"
import Dev from './components/development/Development';

import GlobalStyles from './styles/GlobalStyles';
import { ThemeProvider } from 'styled-components';
import { darkTheme } from './styles/theme';
import React from 'react';

function App() {
  return (
   
    <Routes>
      <Route path='/WebHistory' element = {<ThemeProvider theme={darkTheme}>
          <GlobalStyles/>
          <div>
           hi
          </div>
          </ThemeProvider>}/>
      
      
      <Route path='/' element = { <ThemeProvider theme={darkTheme}>
          <GlobalStyles/>
          <div className="App">
            <Dev/>
            
          </div>
          </ThemeProvider>}/>
       
         
      </Routes>
         
  );
}

export default App;
